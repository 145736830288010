import moment from "moment"
import { toast } from "react-toastify"
import SucessToast from "./componentsToast/SucessToast"
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getTipoUser = () => {
  const item = JSON.parse(localStorage.getItem('userData'))
  const tipo = item?.autenticacao.tipo.toLowerCase()
  return typeof tipo !== 'undefined' ? tipo : null
}
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})


export const getDaysDiff = (start_date, end_date, date_format = 'DD/MM/YYYY') => {
  const getDateAsArray = (date) => {
    return moment(date.split(/\D+/), date_format)
  }
  return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days')
}

export function capitalizeFirstLetter(string) {
  const ArrayString = []
  if (string.split(" ").length >= 2) {
    const Array = string.split(' ')
    for (let index = 0; index < Array.length; index++) {
      const element = Array[index].toLowerCase()
      ArrayString.push(element.charAt(0).toUpperCase() + element.slice(1))
    }
    return ArrayString.join(" ")
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getMonth() {
  const date = new Date()
  return capitalizeFirstLetter(moment(date).format('MMMM'))
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}


export const estados = [
  { name: 'Escolha o estado', nome: '0' },
  { name: 'AL', nome: 'Alagoas' },
  { name: 'AC', nome: 'Acre' },
  { name: 'AP', nome: 'Amapá' },
  { name: 'AM', nome: 'Amazonas' },
  { name: 'BA', nome: 'Bahia' },
  { name: 'CE', nome: 'Ceará' },
  { name: 'DF', nome: 'Distrito Federal' },
  { name: 'ES', nome: 'Espirito Santo' },
  { name: 'GO', nome: 'Goiás' },
  { name: 'MA', nome: 'Maranhão' },
  { name: 'MS', nome: 'Mato Grosso do Sul' },
  { name: 'MT', nome: 'Mato Grosso' },
  { name: 'MG', nome: 'Minas Gerais' },
  { name: 'PA', nome: 'Pará' },
  { name: 'PB', nome: 'Paraíba' },
  { name: 'PR', nome: 'Paraná' },
  { name: 'PE', nome: 'Pernambuco' },
  { name: 'PI', nome: 'Piauí' },
  { name: 'RJ', nome: 'Rio de Janeiro' },
  { name: 'RN', nome: 'Rio Grande do Norte' },
  { name: 'RS', nome: 'Rio Grande do Sul' },
  { name: 'RO', nome: 'Rondônia' },
  { name: 'RR', nome: 'Roraima' },
  { name: 'SC', nome: 'Santa Catarina' },
  { name: 'SP', nome: 'São Paulo' },
  { name: 'SE', nome: 'Sergipe' },
  { name: 'TO', nome: 'Tocantins' }
]
export function numberIsValid(value) {
  const regex = /(\d)\1{6}/g //verifica se há repetição
  let numeroLimpo = value.replace(/\s+/g, '') // retira espaços vazios
  numeroLimpo = typeof (numeroLimpo.split(')')[1]) === 'undefined' ? numeroLimpo.split(' ').shift() : numeroLimpo.split(')')[1]
  numeroLimpo = numeroLimpo.replace(/[^0-9]/g, '')  // retira caracter
  return regex.test(numeroLimpo)
}

export function maskTelefone(e) {
  let value = e.target.value
  value = value.replace(/\D/g, "")      //Remove tudo o que não é dígito
  value = value.replace(/(\d)(\d{3})$/, "$1-$2")
  //Coloca hífen entre o quarto e o quinto dígitos

  e.target.value = value
  return e
}

export function hasAcessSieg() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const access = item?.user.acesso_sieg
  return typeof access !== 'undefined' ? access : null
}
export function hasParceria() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const access = item?.user.parceria
  return typeof access !== 'undefined' ? access : null
}

export const anos = [
  { ano: 2023 },
  { ano: 2022 },
  { ano: 2021 },
  { ano: 2020 },
  { ano: 2019 },
  { ano: 2018 }
]

export function formatNCM(ncm) {
  return ncm.replace(/(\d{4}).(\d{2}).(\d{2})/, "\$1.\$2.\$3")
}

export function copyToClipboard(key, msg) {
  navigator.clipboard.writeText(key)
  toast(<SucessToast message={msg} />)
}

export function formatCurrencyBRL(number, digits = 2) {
  return parseFloat(number).toLocaleString("pt-br", { minimumFractionDigits: digits, style: "currency", currency: "BRL" })
}

export const ambientes = {
  0: "ICMS-ST",
  1: "DIFAL",
  2: "NÃO - TRIBUTADO",
  3: "ZERADO",
  4: "ST - DIFAL",
  5: "BASE DUPLA",
  6: "363 - A - I",
  7: "363 - A - II",
  8: "363 - A - III",
  9: "ANTARTIGO I",
  10: "ANTARTIGO III D",
  11: "ANTARTIGO IIII F",
  12: "ANTECIPAÇÃO IMPORTADOS"
}