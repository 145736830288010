import { toast } from "react-toastify"
import ErrorToast from "../utility/componentsToast/ErrorToast"
import axios from 'axios'
import { store } from '../redux/store'
import { logout } from "../redux/authentication"
export function handleWithError422(error) {
  for (let index = 0; index < Object.keys(error.response.data).length; index++) {
    const element = Object.keys(error.response.data)[index]
    if (typeof (error.response.data[element]) === "object") {
      Object.keys(error.response.data[element]).map((item) => {
        toast(<ErrorToast error={error.response.data[element][item][0]} />, {
          autoClose: false,
          hideProgressBar: true
        })
      })
    } else {
      toast(<ErrorToast error={error.response.data[element]} />, {
        autoClose: 1000 * 5,
        hideProgressBar: true
      })
    }

  }
}

export function handleWithError400(error) {
  if (error.response.request.responseURL !== "https://api.tributei.net/api/upload/xml" && error.response.request.responseURL !== "https://api.tributei.net/api/upload/xml/1") {
    for (let index = 0; index < Object.keys(error.response.data).length; index++) {
      const element = Object.keys(error.response.data)[index]
      toast(<ErrorToast error={error.response.data[element]} />, {
        autoClose: 1000 * 5,
        hideProgressBar: true
      })
    }
  }
}

export function handleWithError401(error) {
  if (error.response?.status === 401 && error.response.request.responseURL !== 'https://api.tributei.net/api/auth/refresh') {
    document.cookie = `admin=true; path=/; max-age=0;`
    window.location = '/login'
    axios.isCancel(error)
    store.dispatch(logout())
  }
}
export function handleWithError429(error) {
  if (error.response?.status === 429 && error.response.request.responseURL === 'https://api.tributei.net/api/auth/refresh') {
    axios.isCancel(error)
    store.dispatch(logout())
    window.location = '/login'
  } else {
    toast(<ErrorToast error='Você fez muitos pedidos, aguarde um momento!' />, {
      hideProgressBar: true,
      toastId: 'pedidos'
    })
  }
}