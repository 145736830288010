import { createContext, useState } from "react"

export const JornadaContext = createContext({})

export function JornadaProvider(props) {
  const [ramo, setRamo] = useState('')
  const [data, setData] = useState({})
  const [dadosPessoais, setDadosPessoais] = useState({})
  function handleRamo(value) {
    setRamo(value)
  }

  function handleData (value) {
    setData(value)
  }

  function handleDadosPessoais(value) {
    setDadosPessoais(value)
  }

  return (
    <JornadaContext.Provider value={{ramo, handleRamo, handleData, data, handleDadosPessoais, dadosPessoais}}>
      {props.children}
    </JornadaContext.Provider>
  )
}