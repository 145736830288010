import { createSlice } from "@reduxjs/toolkit"

export const manutencaoSlice = createSlice({
  name:"Estados",
  initialState:{
    sistema:[],
    guias:[]
  },
  reducers:{
    handleSistema:(state, action) => {
      state.sistema = action.payload
      localStorage.setItem('sistema', action.payload)
    },
    handleGuias:(state, action) => {
      state.guias = action.payload
      localStorage.setItem('guia', action.payload)
    }
  }
})

export const {handleSistema, handleGuias} = manutencaoSlice.actions

export default manutencaoSlice.reducer