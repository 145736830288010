// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import manutencao from './manutencao'
const rootReducer = {
  auth,
  navbar,
  layout,
  manutencao
}

export default rootReducer
