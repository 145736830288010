import { createContext, useState } from "react"

export const SimuladorContext = createContext()

export function SimuladorProvider(props) {
  const [reloadProdutos, setReloadProdutos] = useState(false)

  function handleReloadProdutos() {
    setReloadProdutos(!reloadProdutos)
  }
  return (
    <SimuladorContext.Provider value={{ handleReloadProdutos,  reloadProdutos }}>
      {props.children}
    </SimuladorContext.Provider>
  )
}