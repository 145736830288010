import { createContext, useState } from "react"

export const IcmsDifalContext = createContext({})

export function IcmsDifalProvider(props) {
  const [expandedRows, setExpandedRows] = useState([])
  const [reloadNfe, setReload] = useState(false)
  const handleIcmsDifal = (userId) => {
    const currentExpandedRows = expandedRows
    const isRowExpanded = currentExpandedRows.includes(userId)
    const newExpandedRows = isRowExpanded ? currentExpandedRows.filter(id => id !== userId) : currentExpandedRows.concat(userId)
    setExpandedRows(newExpandedRows)
  }
  function handleResetState() {
    setExpandedRows([])
  }
  function handleGetIdEmLote(ids) {
    setExpandedRows(ids)
  }
  function handleReload() {
    setReload(!reloadNfe)
  }

  return (
    <IcmsDifalContext.Provider value={{ expandedRows, handleIcmsDifal, reloadNfe, handleReload, handleResetState, handleGetIdEmLote }}>
      {props.children}
    </IcmsDifalContext.Provider>
  )
}