import { X } from "react-feather"
import Avatar from '@components/avatar'
import { Fragment } from "react"
export default function ErrorToast(props) {
  return (
    <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='danger' icon={<X size={12}/>} />
        <h6 className='toast-title' style={{color:"red"}}>Erro!</h6>
      </div>
    </div>
    <div  className='toastify-body'>
      <span aria-label='toast-text' >
       {props.error}
       {props.children}
      </span>
    </div>
  </Fragment>
  )
}
