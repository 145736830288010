import { createContext, useState } from "react"

export const RegrasContext = createContext()

export function RegrasProvider(props) {
  const [regras, setRegra] = useState([])
  const [expandedRows, setExpandedRows] = useState([]) 
  const [radio, setRadio] = useState([])
  function handleRadio(value) {
    setRadio(value)
  }

  function handleRegras(value) {
    setRegra(value)
  }
  const handleRegrasCheck = (userId) => {
    const currentExpandedRows = regras 
    const isRowExpanded = currentExpandedRows.includes(userId) 
    const newExpandedRows = isRowExpanded ? currentExpandedRows.filter(id => id !== userId) : currentExpandedRows.concat(userId) 
  
    setExpandedRows(newExpandedRows) 
    handleRegras(newExpandedRows)
  }

  
  return ( 
    <RegrasContext.Provider value={{ regras, handleRegrasCheck, handleRegras, radio, handleRadio }}>
      {props.children}
    </RegrasContext.Provider>
  )
}
