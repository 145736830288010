import { createContext, useState } from "react"

export const CestContext = createContext({})

export function CestProvider(props) {
  const [cestData, setCestData] = useState()
  const [cestState, setCestState] = useState()
  const [currentCest, setCurrentCest] = useState({
    id: 5212,
    cest: "Escolha o CEST",
    aliquota_interna: null,
    descricao: null,
    regra: null,
    subregra: null,
    segmento: null
  })
  function handleCestState(value) {
    setCestState(value)
  }

  function handleCurrentCest(value) {
    setCurrentCest(value)
  }

  function handleCestData(value) {
    setCestData(value)
  }
  return (
    <CestContext.Provider value={{cestState, currentCest, handleCestState, handleCurrentCest, cestData, handleCestData, setCurrentCest }}>
      {props.children}
    </CestContext.Provider>
  )
} 