import { createContext, useState } from "react"

export const RefreshTokenContext = createContext({})
export function RefreshTokenProvider(props) {
  const [keep, setKeep] = useState(false)
  function logoutRefresh() {
    setKeep(false)
    localStorage.removeItem("refresh")
  }
  function handleKeep() {
    setKeep(true)
    localStorage.setItem("refresh", "true")
  }

  return (
    <RefreshTokenContext.Provider value={{ keep, handleKeep, logoutRefresh }}>
      {props.children}
    </RefreshTokenContext.Provider>
  )
}
