import { Listbox } from "@headlessui/react"
import { ChevronDown } from "react-feather"
import { Label } from "reactstrap"
import './Select.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { propTypes } from "react-country-flag"
import PropTypes from 'prop-types'
export default function Select(props) {

  const { Array, setArray, title, selectedArray, w, keyProps } = props
  return (
    <div className="select-container d-flex flex-column">
      <Label>{title}</Label>
      <Listbox value={selectedArray} onChange={setArray}>
        <Listbox.Button className="select-button d-flex align-items-center justify-content-between">
          <span>{selectedArray.name || selectedArray[keyProps]}</span> <ChevronDown size={18} />
        </Listbox.Button>
        <Listbox.Options className="select-container-options" >
          <PerfectScrollbar className={`scroll ${w}`}>
            {Array.map((item) => (
              <Listbox.Option className="d-flex align-items-center" key={item.id || item.name} value={item}>
                {item.name || item[keyProps]}
              </Listbox.Option>
            ))}
          </PerfectScrollbar>
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

Select.prototype = {
  Array: PropTypes.array.isRequired
}