import { createContext, useEffect, useState } from "react"
import { formatCnpjCpf } from "../utility/formatCnpj"
import { getTipoUser } from "../utility/Utils"

export const EmpresasContext = createContext({})

const item = localStorage.getItem('empresaSelecionada')
export function EmpresasProvider(props) {
  const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    return item ? JSON.parse(item) : null
  }
  let empresa = false
  empresa = initialUser() && initialUser()?.user.clientes.length !== 0 ? {
    name: `${formatCnpjCpf(initialUser().user.clientes[0].cnpj.length === 0 || initialUser().user.clientes[0].cnpj === null ? initialUser().user.clientes[0].cpf : initialUser().user.clientes[0].cnpj)}, ${initialUser().user.clientes[0].nome}, ${initialUser().user.clientes[0].estado}`
  } : false
  const empresaInitial = item === null ? empresa : JSON.parse(item)
  const [currentEmpresa, setCurrentEmpresa] = useState(empresaInitial)
  const estado = currentEmpresa === false ? false : currentEmpresa.name.split(",").pop().trim().toLowerCase()
  const cnpjWithoutMask = currentEmpresa === false ? false : currentEmpresa.name.split(",")[0].replace(/[^\d]+/g, "")
  const [idEmpresa, setEmpresa] = useState('')
  function handleEmpresa(value) {
    setCurrentEmpresa(value)
    localStorage.setItem('empresaSelecionada', JSON.stringify(value))
  }

  function handleId(value) {
    setEmpresa(value)
  }

  useEffect(() => {
    if (getTipoUser() === 'sub-cliente') {
      window.addEventListener("load", () => {
        window.Tawk_API.onLoad = function () {
          window.Tawk_API.hideWidget()
        }
      })
      return () => {
        window.addEventListener("load", () => {
          window.Tawk_API.onLoad = function () {
            window.Tawk_API.hideWidget()
          }
        })
      }
    }
  }, [currentEmpresa, getTipoUser()])
  return (
    <EmpresasContext.Provider value={{ currentEmpresa, handleEmpresa, handleId, idEmpresa, cnpjWithoutMask, estado }} >
      {props.children}
    </EmpresasContext.Provider>
  )
}